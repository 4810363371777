<template>
  <div class="register">
    <div class="banner">
      <img src="../../assets/banner.png" alt="">
    </div>

    <div class="form">
      <div class="form_item">
        <div class="label">
          您的姓名
        </div>
        <div class="value">
          <input type="text" v-model="name" placeholder="输入姓名">
        </div>
      </div>
      <div class="form_item">
        <div class="label">
          您的电话
        </div>
        <div class="value">
          <input type="number" v-model="phone" placeholder="输入电话">
        </div>
      </div>
    </div>
    <div class="checkbox_container">
      <van-checkbox v-model="checked" icon-size="0.28rem" shape="square" checked-color="#0CC598" ></van-checkbox>
      <span class="checkbox_text">已阅读并同意</span>
      <span @click="isModel=true">
      《个人信息保护声明》
    </span>
    </div>
    <div class="btn_container">
      <button class="submit" @click="submit">提交</button>
    </div>
    <div class="footer">
      <span>深圳市水母汽车服务有限公司</span>
    </div>

    <div class="model" v-if="isModel">
      <div class="model_container">
        <van-icon class="vant_icon_close" @click="isModel=false" name="cross"/>
        <div class="wrapper_content">
          <Agreement />
        </div>
        <div class="footer_btn" @click="isModel=false">我已经阅读</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Checkbox, CheckboxGroup, Icon, Toast, Dialog} from 'vant';
  import Agreement from "./agreement"
  import axios from 'axios'

  export default {
    name: "index",
    components: {
      [Checkbox.name]: Checkbox,
      [CheckboxGroup.name]: CheckboxGroup,
      [Icon.name]: Icon,
      Agreement
    },
    data() {
      return {
        checked: false,
        isModel: false,
        name: '',
        phone: '',
      }
    },
    computed: {
      domain() {
        const {VUE_APP_CURENV, NODE_ENV} = process.env
        if (VUE_APP_CURENV === 'test' || NODE_ENV === 'development') {
          return {
            http: 'http://fapisit.shuimuche.com'
          }
        }
        //zeng  2022.03.25
        //https://bapi.shuimuche.com/api/auction/customer/save
        return {http: 'https://bapi.shuimuche.com'}
      }
    },
    methods: {
      submit() {
        const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/  //手机号码
        if (!this.name.trim()) return Toast('请输入姓名')
        if (!this.phone) return Toast('请输入手机号码')
        if (!reg.test(this.phone)) return Toast('请输入正确的手机号码')
        if (!this.checked) return Toast('请勾用户选协议')
        axios.post(`${this.domain.http}/api/auction/customer/save`, {
          custName: this.name.trim(),
          custPhone: this.phone
        }).then(({data}) => {
          if (data.status === 200) {
            Dialog.alert({
              message: '提交成功',
              theme: 'round-button',
            })
          } else {
            Toast(data.msg)
          }
        })
      }
    },
    mounted() {
      document.title = '水母汽车'
      const setRem = () => {
        let screenWidth = window.innerWidth;
        let designWidth = 750;
        let ratio = 100;
        let fontSize = screenWidth / designWidth * ratio;
        if (screenWidth > 750) {
          fontSize = 100
        }
        document.documentElement.style.fontSize = fontSize + "px";
      }
      window.onresize = setRem
      setRem()
    }
  }
</script>

<style scoped lang="scss">
  .banner {
    width: 100%;

    > img {
      width: 100%;
      display: block;
    }
  }

  .form {
    font-size: .32rem;
    font-family: PingFang HK, PingFang HK-Regular;
    padding: .32rem;

    .form_item {
      margin-top: .34rem;
    }
  }

  input {
    outline: none;
    font-size: .32rem;
    margin: 0;
    border: none;
    color: #3C3C3C;
    padding: .32rem 0;
    width: 100%;
    border-bottom: 1px solid #EDF0F3;

    &::placeholder {
      color: #C8CBD0;
      font-size: .32rem;
    }
  }

  .btn_container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 .32rem;

    .submit {
      border: none;

      width: 100%;
      box-sizing: border-box;
      background: #0cc598;
      text-align: center;
      line-height: .96rem;
      font-size: .32rem;
      color: #fff;
      border-radius: 4px;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    padding-bottom: .32rem;
    background: #fff;
    width: 100%;

    > span {
      display: block;
      text-align: center;
      color: #a1a1a1;
      font-size: .28rem;
    }
  }

  .checkbox_container {
    display: flex;
    align-items: center;
    padding-left: .32rem;
    margin-top: .28rem;

    .checkbox_text {
      color: #9ca2ad;
      font-size: .24rem;
      margin-left: .12rem;
    }

    > span {
      color: #0CC598;
      font-size: .24rem;
    }

    .checkbox {
      width: .28rem;
      background: #9ca2ad;
      height: .28rem;
      padding: 0;
      margin-right: .16rem;
    }

  }

  .model {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;

  }

  .register {
    padding-bottom: 100px;

    .model_container {
      margin: 0 auto;
      width: 80%;
      height: 85%;
      background: #fff;
      margin-top: 10%;
      border-radius: 4px;
      box-sizing: border-box;
      padding: .82rem 0 1.2rem 0;
      position: relative;

      .vant_icon_close {
        position: absolute;
        right: .05rem;
        padding: .2rem;
        top: 5px;
        font-size: .38rem;
        color: #6b6d71;
      }

      .footer_btn {
        font-size: .32rem;
        line-height: 3.2em;
        position: absolute;
        bottom: 0;
        color: #000;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        font-weight: bold;
        width: 100%;
        border-top: 1px solid #e2e2e2;
        z-index: 99;
        background: #fff;
        text-align: center;
      }
    }

    .wrapper_content {
      width: 100%;
      background: #fff;
      height: 100%;
      box-sizing: border-box;
      padding: 0 .42rem;
      overflow-y: auto;
    }
  }
</style>
